<template>
  <div>
    <mdb-carousel
        :items="3"
        slide
        :interval="2000"
    >
      <template v-for="(i, index) in items" #[index+1]>
        <div :key="index" style="cursor: pointer">
          <mdb-row>
            <img :src="imgs[i]" class="img-fluid" alt/>
          </mdb-row>
          <mdb-row>
            <img :src="imgs[i+1]" class="img-fluid" alt/>
          </mdb-row>
          <mdb-row>
            <img :src="imgs[i+2]" class="img-fluid" alt/>
          </mdb-row>
        </div>
      </template>
    </mdb-carousel>

  </div>
</template>

<script>
import {mdbCarousel, mdbRow} from "mdbvue";

export default {
  name: "CarouselPage",
  components: {
    mdbCarousel,
    mdbRow
  },
  data() {
    return {
      imgs: [
        "https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(2).jpg",
        "https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(3).jpg",
        "https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(4).jpg",
        "https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(5).jpg",
        "https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(6).jpg",
        "https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(7).jpg",
        "https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(8).jpg",
        "https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(9).jpg",
        "https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(10).jpg",
      ],
      visible: false,
      index: 0
    };
  },
  methods: {
    showLightbox(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    }
  },
  computed: {
    items() {
      return this.imgs
          .map((_, i) => i)
          .filter((_, i) => i % 3 === 0);
    }
  }
};
</script>